export const dataEnv = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      API_URL: 'http://localhost:3000/api/v1',
      NAME_PLATFORM: 'Radiorama',
      BUCKET_RATES: 'rates-develop'
    }
  } else if (process.env.NODE_ENV === 'production') {
    return {
      API_URL: 'https://apiradiorama.radiodesk.com.mx/api/v1',
      NAME_PLATFORM: 'Radiorama',
      BUCKET_RATES: 'rates-stage'
    }
  } else {
    return {
      API_URL: 'https://api-stage-radiorama.radiodesk.com.mx/api/v1',
      NAME_PLATFORM: 'Radiorama',
      BUCKET_RATES: 'rates-rd'
    }
  }
}
