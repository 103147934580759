<script setup>
import { ref } from 'vue'
import router from '@/router'
/* import logo from '../assets/login/logo.png' */
import RadioramaSombraBlanco1 from '../assets/logoRadiorama/RadioramaSombraBlanco1.png'
import eyeOpen from '../assets/general/eye-open.png'
import eyeClosed from '../assets/general/eye-closed.png'
import { getLogin, setLogin } from '@/services/auth'

const logo = ref(RadioramaSombraBlanco1)

const email = ref('')
const password = ref('')
const emailError = ref(false)
const passwordError = ref(false)
const isPasswordVisible = ref(false)

const handleSubmit = (message, data) => {
  if (message === 'USER') {
    emailError.value = true
  } else {
    emailError.value = false
  }

  if (message === 'PASS') {
    passwordError.value = true
  } else {
    passwordError.value = false
  }

  if (message !== 'USER' && message !== 'PASS') {
    emailError.value = false
    passwordError.value = false
    if (document.getElementById('rememberCheck').checked) {
      setLogin(message, '1y')
    } else {
      setLogin(message, '1d')
    }
    if (data) {
      localStorage.setItem('idrd', data.id)
      localStorage.setItem('typerd', data.typeId)
      localStorage.setItem('name', data.name)
      localStorage.setItem('email', data.email)
      localStorage.setItem('logo', data.logo)
      localStorage.setItem('rdspe', data.rds)
      localStorage.setItem('clientName', data.clientName)
      if (data.accepted) {
        localStorage.setItem('accepted', true)
      } else {
        localStorage.setItem('accepted', false)
      }
      if (data.isComplete === 1) {
        if (data.typeId === 1) {
          router.push({ name: 'RadioCampaingListView' })
        } else if (data.typeId === 2) {
          router.push({ name: 'AgencyBookingCampaingView' })
        } else if (data.typeId === 3) {
          router.push({ name: 'AnuncianteBookingCampaingView' })
        }
      } else {
        if (data.typeId === 1) {
          router.push({ name: 'RegisterRadio' })
        } else if (data.typeId === 2) {
          router.push({ name: 'RegisterAgencia' })
        } else if (data.typeId === 3) {
          router.push({ name: 'RegisterAdvertiser' })
        }
      }
    }
  }
}

const handleRecoveryPassword = () => {
  sessionStorage.setItem('email', email.value)
  router.push({ name: 'PasswordRecoveryView' })
}

const tooglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value
}

const loginSend = () => {
  const structureObject = {
    email: email.value,
    password: password.value
  }
  getLogin(structureObject).then((response) => {
    if (response.data.message !== undefined) {
      handleSubmit(response.data.message)
    } else {
      handleSubmit(response.data.token, response.data)
    }
  }).catch((e) => { })
}

const keypressHandler = (e) => {
  console.log('Dio click', e)
  if (e.keyCode === 13) {
    loginSend()
  }
}
</script>

<template>
  <div class="main-container">
    <div class="text-center pt-4">
      <img :src="logo" alt="Logo" class="img-fluid">
    </div>

    <div class="container mx-auto">
      <form class="mx-auto" @submit.prevent="loginSend">
        <h1 class="text-center">Inicio de sesión</h1>
        <div class="mb-4">
          <label for="emailInput" :class="emailError ? 'error' : ''" class="form-label text-start">Correo electrónico</label>
          <input type="email" name="email" :class="emailError ? 'error' : ''" class="form-control" id="emailInput" placeholder="correo@mail.com" required v-model.trim="email">
          <p v-if="emailError" class="form-text error">No pudimos encontrar tu cuenta. Intenta de nuevo.</p>
        </div>

        <div class="mb-3 position-relative">
          <label for="passwordInput" :class="passwordError ? 'error' : ''" class="form-label ">Contraseña</label>
          <input @keyup="keypressHandler($event)" v-if="isPasswordVisible" type="text" name="passwordInput" :class="passwordError ? 'error' : ''" class="form-control" placeholder="Ingresa tu contraseña" required v-model.trim="password">
          <input @keyup="keypressHandler($event)" v-else type="password" name="password" :class="passwordError ? 'error' : ''" class="form-control" id="passwordInput" placeholder="Ingresa tu contraseña" required v-model.trim="password">
          <img v-if="isPasswordVisible" @click="tooglePasswordVisibility" :src="eyeClosed" class="eye-closed">
          <img v-else @click="tooglePasswordVisibility" :src="eyeOpen" class="eye-open">
          <p v-if="passwordError" class="form-text error">Contraseña incorrecta. Intenta de nuevo o selecciona "Olvidé mi contraseña" para cambiarla.</p>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <div class="col form-check d-flex gap-2 m-0 align-items-center">
            <input type="checkbox" class="form-check-input mt-0" id="rememberCheck">
            <label class="form-check-label  remember-check" for="rememberCheck">Recordarme siempre</label>
          </div>

          <button type="button" tabindex="5" @click="handleRecoveryPassword" class="btn text-end text-white fw-bold recovery-password">Olvidé mi contraseña</button>
        </div>

        <div class="d-flex align-items-center justify-content-between form-buttons gap-5">
          <!--RouterLink :to="{ name: 'StatusRegister' }" class="btn btn-create text-left p-0">Solicita una cuenta</RouterLink-->
          <!-- <button type="button" class="btn btn-create text-left p-0">Crea una cuenta</button> -->
          <button tabindex="0" type="submit" class="btn btn-primary rounded-pill btn-login">Iniciar Sesión</button>
        </div>
      </form>
    </div>

    <footer class="footer flex-auto d-flex justify-content-center gap-4">
      <a href="https://radiodesk.com.mx/terminos-y-condiciones-grupos-de-radio-y-emisoras-saas/" target="_blank"><u>Términos y Condiciones Grupos de Radio y Emisoras.</u></a>
      <a href="https://radiodesk.com.mx/terminos-y-condiciones-anunciantes-y-agencias-saas/" target="_blank"><u>Términos y Condiciones Agencias y Anunciantes.</u></a>
      <a href="https://radiodesk.com.mx/aviso-de-privacidad-integral/" target="_blank"><u>Política de Privacidad</u></a>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
@import "@/_scss/global/_variables.scss";

.main-container {
  background: url('../assets/logoRadiorama/Ondas1.png') center center/cover no-repeat, linear-gradient(0deg, var(--black, #000) 0%, var(--black, #000) 100%), var(--white, #FFF);
  width: 100vw;
  height: 100%;

  img {
    width: 100%;
    max-width: 573px;
    margin-bottom: 26px;
  }

  .container {
   padding: 0;
   color: $color-primary-white;

    form {
      font-size: 18px;
      max-width: 502px;

      h1 {
        margin-bottom: 45px;
        font-family: "Helvetica";
        font-size: 32px;
      }
      .eye-open, .eye-closed {
        width: 24px;
        margin: 0;
        position: absolute;
        top: 34px;
        right: 10px;
        cursor: pointer;
      }
      p.error {
        color: $color-error-medium;
        line-height: 24px;
        font-size: 14px;
      }

      label {
        font-family: Lato;

        &.error {
          color: $color-error-medium;
        }
      }

      input {
        border-radius: 20px;
        border: none;
        font-family: Lato;
        padding: 9px 16px;
        font-size: 18px;

        &.error {
          border: 1px solid $color-error-medium;
        }

        &::placeholder {
          color: $color-nautral-50;
        }
      }
      .form-check-input {
        border-radius: 2px;
        border: 1px solid $color-primary-black;
        padding: 0;
        width: 16px;
        height: 16px;
      }
      .remember-check,
      .recovery-password {
        font-size: 14px;
        border: none;

        &:hover {
          text-decoration: underline;
        }
      }
      .form-check-input:checked {
        background-color: $color-secondary-01;
      }
      .form-buttons {
        margin-top: 51px;
        font-family: Lato;
        .btn {
          font-size: 18px;
          font-weight: 700;
        }
        .btn-create {
          color: $color-primary-white;

          &:hover {
            color: $color-secondary-02;
          }
        }
        .btn-login{
          padding: 16px 24px;
          width: 245px;
          font-family: Lato !important;
          background: $color-gradient-two;

          &:hover {
            background: $color-gradient-four;
          }
        }
        .btn-primary {
        border: 1px solid $color-secondary-01;
      }
      }
    }

  }

  footer {
    margin-top: 90px;
    padding-bottom: 32px;
    u {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      color: $color-primary-white;

      &:hover {
        color: $color-secondary-02;
      }
    }
  }
}

@media screen and ( min-height: 700px ) {
  .main-container {
    height: 100vh;
  }
}

</style>
